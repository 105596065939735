<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h4 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>&nbsp; Jobworker's Invoice List</h4>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="invoice_list_table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="_id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" >Date</th>
          <th data-field="doc_no" data-width="100" >Doc No</th>
          <th data-field="ldgr.name" >Jobworker</th>
          <th data-field="ref_no" data-width="100">Inv No</th>
          <th data-field="ref_date" data-width="110" data-formatter="dateFormatter" >Inv Date</th>
          <th data-field="net_amount" data-width="100" data-align="right" data-formatter="indianFormat">Amount</th>
          <th data-field="remarks" >Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <vmodal name="invoice-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @before-open="beforeOpen" @before-close="beforeClose" >
      <InvoiceForm v-bind:myvoucher="invoice" ></InvoiceForm>
    </vmodal>

  </div>

</template>

<script>
  import InvoiceForm from '@/components/v1/tms/jobworkinvoice/JobworkInvoiceForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'InvoiceView',
    components: {
      InvoiceForm,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        invoice: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":0,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        finyear:  2018,
        series: 302,
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#invoice_list_table');
      this.$data.mytable.bootstrapTable();

      this.$data.mytable.on('post-body.bs.table', function (e) {
        $('[data-toggle="popover"]').popover();
      });


      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument(id);
          }else if ($(e.target).text() === 'Cancel') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#invoice_list_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();
    },
    methods: {
      closeModal() {
        this.$modal.hide('invoice-window');
      },
      beforeOpen(){

      },
      beforeClose(){
        // this.$data.invoice = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
      },
      showModal () {
        this.$modal.show('invoice-window');
      },

      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');

            self.$modal.show('invoice-window');

          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.invoice.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.invoice)
        };

        swal({
          title: 'What is the reason for cancel this transaction?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              if(resp.accepted){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.message, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });


      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#txtsearch').val('');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${store.state.user.finyear}/${self.series}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( _.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
              $('[data-toggle="popover"]').popover();
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });

      }
    }

  }
</script>

<style scoped>

</style>
